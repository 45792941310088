import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb';
import VideoCategoryNav from '../../../components/VideoCategoryNav';
import { useLocation } from '@reach/router';

const Testimonials = ({ pageContext }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const [videoCount, setVideoCount] = useState(6);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const location = useLocation();

	const videos = [
		{
			id: 'eihPzvlUn_s',
			url: 'https://www.youtube.com/embed/eihPzvlUn_s?si=8d7GCx3dWXaKjCKG',
			title: 'Stories and Testimonials from hear.com Customers | hear.com',
			subtitle:
				'Discover the heartwarming stories and testimonials of satisfied hear.com customers in this captivating video'
		},
		{
			id: 'LSysf7_xogE',
			url: 'https://www.youtube.com/embed/LSysf7_xogE?si=lTYVEI9qrXMC_VfZ',
			title: 'Meet Whitney - Customer Testimonial | hear.com',
			subtitle: 'Get to know Whitneys experience of the process to better hearing with hear.com'
		},
		{
			id: 'hjo1Iy3l2Qw',
			url: 'https://www.youtube.com/embed/hjo1Iy3l2Qw?si=yPzjjaoybPNM_LiH',
			title: 'Meet Scott - Customer Testimonial | hear.com',
			subtitle: 'Listen to Scotts journey to realizing his need of hearing aids and how they changed his life.'
		},
		{
			id: 'MSBvgD1Sxk4',
			url: 'https://www.youtube.com/embed/MSBvgD1Sxk4?si=WaVYzKkxcnM6umhe',
			title: 'Meet Martin - Customer Testimonial | hear.com',
			subtitle:
				'Martin renewed his sense of wonder and appreciation for the richness of life after getting hear.com hearing aids.'
		},
		{
			id: 'WsXI2a8eh4I',
			url: 'https://www.youtube.com/embed/WsXI2a8eh4I?si=LPmy5dWFnBrkA2Xz',
			title: 'Meet Michael - Customer Testimonial | hear.com',
			subtitle: 'After getting his hearing aids, he has rediscovered the joy of engaging in meaningful conversations.'
		},
		{
			id: 'gy-9swZ4iH4',
			url: 'https://www.youtube.com/embed/gy-9swZ4iH4?si=ZeVOHdw_PF61PzXA',
			title: 'Meet Bill - Customer Testimonial | hear.com',
			subtitle: 'Bill joyfully recounts how he regained his life when he started wearing hearing aids'
		},
		{
			id: 'fp0FEsdyGRY',
			url: 'https://www.youtube.com/embed/fp0FEsdyGRY?si=dw5f7n64f7NnJtCb',
			title: 'Meet Jimmy - Customer Testimonial | hear.com',
			subtitle:
				'Jimmy passionately shares that every day you postpone addressing your hearing loss, you are missing out on valuable experiences'
		}
	];

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const videoId = params.get('videoID');
		if (videoId) {
			const video = videos.find((v) => v.id === videoId);
			setSelectedVideo(video);
		}
	}, [location]);

	const handleShowMore = () => {
		setVideoCount((prevCount) => prevCount + 6);
	};

	const visibleVideos = videos.slice(0, videoCount);

	return (
		<Layout>
			<Seo
				title="hear.com hearing aids instructional videos | hear.com"
				description="Watch videos on care, maintenance, cleaning, how-to guides, and testimonials for in-the-ear & behind-the-ear hearing aids by hear.com"
			/>
			<div className="o-wrapper">
				<div className="o-row-3p5"></div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<VideoCategoryNav />
				<div className="c-video-cards-wrapper o-row-3p5">
					{selectedVideo ? (
						<div className="c-video-container">
							<iframe
								className="c-video-card"
								title={selectedVideo.title}
								src={selectedVideo.url}
								allow="accelerometer; autoplay; picture-in-picture"
								allowFullScreen
							></iframe>
							<p className="c-video-title">{selectedVideo.title}</p>
							<p className="c-video-subtitle">{selectedVideo.subtitle}</p>
						</div>
					) : (
						visibleVideos.map((video, index) => (
							<div className="c-video-container" key={index}>
								<iframe
									className="c-video-card"
									title={video.title}
									src={video.url}
									loading={index < 6 ? 'eager' : 'lazy'}
									allow="accelerometer; autoplay; picture-in-picture"
									allowFullScreen
								></iframe>
								<p className="c-video-title">{video.title}</p>
								<p className="c-video-subtitle">{video.subtitle}</p>
							</div>
						))
					)}
				</div>
				{videos.length > videoCount && (
					<div className="c-show-more-wrapper">
						<button className="c-show-more-btn" onClick={handleShowMore}>
							Show More
						</button>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default Testimonials;
